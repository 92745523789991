@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;700&display=swap');
@import 'react-big-calendar/lib/css/react-big-calendar.css';

.scroll-smooth {
    scroll-behavior: smooth;
    transition-duration: 0.5s; /* Ajusta la duración de la animación según tus preferencias */
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); /* Ajusta la función de temporización según tus preferencias */
  }


  /* .rbc-event {
    @apply bg-orange-400 text-white h-auto w-2  ;
  } */


